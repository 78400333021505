<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>账户管理</p>
			<i>/</i>
			<p @click="jumpPage">部门列表</p>
			<i>/</i>
			<p class="active">部门编辑</p>
		</nav>
	
		<div class="page_edit">
	
			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="name">
						<p class="form_label_p">部门名称</p>
						<el-input class="input_long" v-model="ruleForm.name" placeholder="请输入部门名称"></el-input>
					</el-form-item>
					<el-form-item label="" prop="rank">
						<p class="form_label_p">级别</p>
						<el-input class="input_long" v-model="ruleForm.rank" placeholder="数字越小越靠前">
						</el-input>
					</el-form-item>
					<div  class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
	
		</div>
	
	</div>
</template>

<script>
	
	export default {
		name: 'departmentEdit',
		data() {
			return {
				ruleForm: {
					name: '',
					rank: '',
				},
				rules: {
					name: [{
						required: true,
						message: '部门名称不能为空',
						trigger: 'blur'
					}],
					rank: [
						{
							required: true,
							message: '部门级别不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^\+?[1-9][0-9]*$/,
							message: '只能是非0的正整数',
						},
					]
				},
				modifyBtn: true, // 默认创建
			}
		},
		components: {
			
		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				
				// 获取部门做修改
				this.getDepartmentInfo();
			}
		},
		methods: {
			
			// 获取部门做修改
			getDepartmentInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/classinfo`,
					data: {
						class_id: this.$route.query.id,
					}
				}).then( res => {
					if(res.code == '0'){
						this.ruleForm.name = res.data.class_name;
						this.ruleForm.rank = res.data.class_rank;
					}
					else{
						this.$message.error(`${res.msg}`);
					}
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/addclass`,
							data: {
								class_name: this.ruleForm.name,
								class_rank: this.ruleForm.rank,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}admin/editclass`,
							data: {
								class_id: this.$route.query.id,
								class_name: this.ruleForm.name,
								class_rank: this.ruleForm.rank,
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage(){
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

</style>
